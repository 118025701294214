import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import HotelAutoComplete from "../../../components/common/HotelAutoComplete/HotelAutoComplete";
import {
  CustomFormControl,
  DisclaimerContainer,
  FeedbackMessage,
  FormButton,
  Label,
} from "./ContactFormFieldsHelpers";
import { ContactFormFieldsProps } from "./ContactFormFieldsProps";

const ContactFormFields: React.FC<ContactFormFieldsProps> = ({
  control,
  register,
  errors,
  values,
  formGroup,
  handleHotelSelection,
  required,
  validateMaxLengthMessage,
  validateMinLengthMessage,
  handleTermsModalShow,
  handlePrivacyModalShow,
  isSubmitting,
  handleSubmit,
  onSubmit,
}) => {
  return (
    <Form
      id="contact-us-form"
      noValidate
      validated={false}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.Control type="hidden" name="id" ref={register} />

      <Row>
        <Form.Group as={Col} lg={6} className={formGroup} controlId="firstName">
          <Label id="firstName-label" title="First Name" isRequired={true} />
          <Controller
            as={
              <CustomFormControl isInvalid={!!errors.firstName} ariaRequired />
            }
            type="text"
            name="firstName"
            control={control}
            rules={{
              required: required,
              maxLength: {
                value: 50,
                message: validateMaxLengthMessage(50),
              },
            }}
            defaultValue={values.firstName || ""}
          />
          <FeedbackMessage error={errors.firstName} />
        </Form.Group>

        <Form.Group as={Col} lg={6} className={formGroup} controlId="lastName">
          <Label id={"lastName-label"} title={"Last Name"} isRequired={true} />
          <Controller
            as={
              <CustomFormControl isInvalid={!!errors.lastName} aria-required />
            }
            type="text"
            name="lastName"
            control={control}
            rules={{
              required: required,
              maxLength: {
                value: 50,
                message: validateMaxLengthMessage(50),
              },
            }}
            defaultValue={values.lastName || ""}
          />
          <FeedbackMessage error={errors.lastName} />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} lg={6} className={formGroup} controlId="phone">
          <Label
            id={"phoneNumber-label"}
            title={"Phone Number"}
            isRequired={true}
          />
          <Controller
            as={
              <CustomFormControl
                isInvalid={!!errors.phone}
                aria-required
                maxLength={13}
              />
            }
            type="text"
            name="phone"
            control={control}
            rules={{
              pattern: {
                value: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s.0-9]*$/,
                message: "A valid phone number is required.",
              },
              required,
              minLength: {
                value: 10,
                message: validateMinLengthMessage(10),
              },
            }}
            defaultValue={values.phone || ""}
          />
          <FeedbackMessage error={errors.phone} />
        </Form.Group>

        <Form.Group as={Col} lg={6} className={formGroup} controlId="email">
          <Label
            id={"emailAddress-label"}
            title={"Email Address"}
            isRequired={true}
          />
          <Controller
            as={<CustomFormControl isInvalid={!!errors.email} aria-required />}
            name="email"
            type="email"
            control={control}
            rules={{
              required,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "A valid Email address is required.",
              },
              maxLength: {
                value: 50,
                message: validateMaxLengthMessage(50),
              },
            }}
            defaultValue={values.email || ""}
          />
          <FeedbackMessage error={errors.email} />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group
          as={Col}
          lg={6}
          className={formGroup}
          controlId="confirmationNo"
        >
          <Label
            id={"reservationID"}
            title={"Reservation ID (optional)"}
            isRequired={false}
          />
          <Controller
            as={<CustomFormControl isInvalid={!!errors.confirmation} />}
            type="text"
            name="confirmation"
            control={control}
            rules={{
              maxLength: {
                value: 20,
                message: validateMaxLengthMessage(20),
              },
            }}
            defaultValue={values.confirmation || ""}
          />
          <FeedbackMessage error={errors.confirmation} />
        </Form.Group>

        <Form.Group
          as={Col}
          lg={6}
          className={formGroup}
          controlId="contactHotelCode"
        >
          <Label
            id={"hotelName-label"}
            title={"Hotel Name"}
            isRequired={true}
          />
          <HotelAutoComplete
            onHotelSelection={handleHotelSelection}
            id="contactHotelCode"
            inputRef={register({ required })}
            name="contactHotelCode"
            errors={errors.contactHotelCode}
            suggestions={values.contactHotelCode}
            customIconClass=""
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} lg={6} className={formGroup} controlId="memberId">
          <Label
            id={"memberId-lable"}
            title={"Member ID (optional)"}
            isRequired={false}
          />
          <Controller
            as={<CustomFormControl isInvalid={!!errors.memberId} />}
            type="text"
            name="memberId"
            control={control}
            rules={{
              maxLength: {
                value: 20,
                message: validateMaxLengthMessage(20),
              },
            }}
            defaultValue={values.memberId || ""}
          />
          <FeedbackMessage error={errors.memberId} />
        </Form.Group>
      </Row>

      <Form.Group className={formGroup} controlId="details">
        <Label id={"comments-label"} title={"Comments"} isRequired={true} />
        <Controller
          as={
            <CustomFormControl
              as="textarea"
              rows={5}
              isInvalid={!!errors.details}
              aria-required
            />
          }
          name="details"
          control={control}
          rules={{ required }}
          defaultValue={values.details || ""}
          placeholder="Enter comments here"
        />
        <FeedbackMessage error={errors.details} />
      </Form.Group>
      <FormButton isSubmitting={isSubmitting} />
      <DisclaimerContainer
        handleTermsModalShow={handleTermsModalShow}
        handlePrivacyModalShow={handlePrivacyModalShow}
      />
    </Form>
  );
};

export default ContactFormFields;
